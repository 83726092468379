import { BlogHero, PostsGrid } from '@components/Blog'
import Head from '@components/Head'
import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      posts: allWpBlogPost {
        nodes {
          title
          excerpt
          id
          slug
          date(formatString: "dddd, MMMM D YYYY")
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <Head
        title="MRS Blog"
        description="Updates and anouncements from MRS Company."
      />
      <BlogHero />
      <PostsGrid data={data} />
    </>
  )
}
export default BlogPage
