import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Section } from '@components/common'

const BlogHero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "images/stock/hero_7.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Section
      verticalSpacing="xxl"
      backgroundImage={data.image.childImageSharp.fluid}
      theme="dark"
    >
      <Container>
        <div className="md:w-3/5 w-full">
          <h1>MRS Blog</h1>
        </div>
      </Container>
    </Section>
  )
}
export default BlogHero
