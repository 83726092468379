import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Section } from '@components/common'
import Button from '@mrs-ui/Button'

const CaseStudiesHero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "images/stock/h009.jpeg" }) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Section
      verticalSpacing="xxl"
      backgroundImage={data.image.childImageSharp.fluid}
      theme="dark"
      overlay
    >
      <Container>
        <div className="md:w-5/12 w-full">
          <h1 className="mb-7">MRS Case Studies</h1>
          <p className="text-large">
            Learn how MRS helps organizations of all sizes accelerate data-driven
            innovation and transform with cloud technology.
          </p>
          <Button appearance="primary" link="/contact" className="mt-6">
            Discuss your solution
          </Button>
        </div>
      </Container>
    </Section>
  )
}
export default CaseStudiesHero
