import ContactForm from '@components/ContactForm'
import { Icon } from '@mrs-ui'
import React from 'react'

const BlogSidebar = () => {
  return (
    <aside>
      <div className="mb-4">
        <h3>Follow</h3>
        <div className="flex flex-col  mt-4">
          <a
            target="_blank"
            href="https://github.com/MRSCompanyLtd"
            className="text-mini ml-1.5 mr-1.5 mb-2 text-shade700 link-transition hover:text-primary hover:no-underline"
          >
            <span className="flex items-center">
              <Icon name="GitHub" className="h-4 w-4 flex fill-current mr-2" />
              Github
            </span>
          </a>

          <a
            target="_blank"
            href="https://twitter.com/_MRSCompany"
            className="text-mini ml-1.5 mr-1.5 mb-2 text-shade700 link-transition hover:text-primary hover:no-underline"
          >
            <span className="flex items-center">
              <Icon name="Twitter" className="h-4 w-4 flex fill-current mr-2" />
              Twitter
            </span>
          </a>

          <a
            target="_blank"
            href="https://ca.linkedin.com/company/m-r-s--company-ltd-"
            className="text-mini ml-1.5 mr-1.5 mb-2 text-shade700 link-transition hover:text-primary hover:no-underline"
          >
            <span className="flex items-center">
              <Icon name="Linkedin" className="h-4 w-4 flex fill-current mr-2" />
              LinkedIn
            </span>
          </a>
        </div>
      </div>
      <div>
        <h3 className="mb-2">Contact MRS</h3>
        <ContactForm />
      </div>
    </aside>
  )
}
export default BlogSidebar
